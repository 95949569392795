.home {
  padding: 68px 0;
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1130px;
  padding: 0 15px;
  margin: auto;
}

.content, .about {
  position: relative;
  z-index: 3;
}

.content {
  max-width: 770px;
  width: 100%;
  margin-left: 330px;
}

.text {
  font-size: 65px;
  letter-spacing: -0.2px;
  line-height: 90px;
  margin-top: 0px;
  margin-bottom: 56px;
  font-weight: 300;
}

.skills {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  max-width: 770px;
  width: 100%;
  text-align: center;
}

/* .skill {
  border: 1px solid #575757;
  border-radius: 50%;
} */

@media screen and (max-width: 1246px) {
  .container {
    max-width: 767px;
  }

  .content {
    width: 100%;
    margin-left: auto;
  }
}


@media screen and (max-width: 900px) {
  .text {
    font-size: 56px;
  }

  .skills {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .text {
    font-size: 48px;
    line-height: 1.3;
    max-width: 95%;
  }

  .skills {
    max-width: 90%;
  }
}