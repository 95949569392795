.nav {
  display: flex;
  flex-direction: column;
  border: 1px solid #575757;
  border-radius: 30px;
  position: fixed;
  right: 50px;
  top: 322px;
  text-align: center;
  gap: 20px;
  padding: 24px 10px;
  z-index: 20;
}

ul {
  list-style: none;
  margin: 0;
}

i {
  color: white;
}


@media screen and (max-width: 575px) {
  .nav {
    right: 15px;
  }
}