
.container {
  max-width: 1130px;
  padding: 0 15px;
  margin: auto;
}

.content {
  max-width: 770px;
  width: 100%;
  margin-left: auto;
  /* margin-left: 330px; */
}


.text {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 30px;
  max-width: 610px;
  font-size: 18px;
}

@media screen and (max-width: 1246px) {
  .container {
    max-width: 767px;
  }

  .content {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
  }
}

@media screen and (max-width: 575px) {
  .text {
    max-width: 92%;
  }
}