.resume {
  padding-top: 90px;
  padding-bottom: 90px;
}

.container {
  max-width: 1130px;
  padding: 0 15px;
  margin: auto;
}

.content {
  max-width: 770px;
  width: 100%;
  margin-left: auto;
  /* margin-left: 330px; */
}

.item {
  position: relative;
  padding-left: 74px;
  padding-bottom: 68px;
}

span {
  color: #ad6a75;
}

.item:last-child {
  padding-bottom: 0;
}

.item::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background: #656565;
  border-radius: 50%;
  left: -6px;
  top: 7px;
  transition: .3s;
}

.item::after {
  content: '';
  background: #656565;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 10px;
}

.date {
  margin-bottom: 28px;
}

h2 {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

p {
  margin-bottom: 18px;
}

.resumeLink {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1246px) {
  .container {
    max-width: 767px;
  }

  .content {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
  }
}