*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body,
.root {
  min-height: 100%;
  margin: 0;
}

body {
  box-sizing: border-box;
  margin-top: 40px;
  font-family: Inter, sans-serif;
  color: #ffffff;
  background-color: #1f1f1f;;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

a,
a:visited {
  text-decoration: none;
  color: #ffffff;
}

section {
  align-items: center;
  display: block;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  line-height: 18pt;
  padding: 90px 0 90px 0;
  overflow: hidden;
}

.profileSideBar {
  max-width: 350px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #565656;
  position: fixed;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  padding: 50px;
  z-index: 10;
}


@media screen and (min-width: 2000px) {
  .profileSideBar {
    max-width: 485px;
  }
}

@media screen and (max-width: 1246px) {
  .profileSideBar {
    /* display: box; */
    position: relative;
    max-width: 767px;
    left: 0;
    margin: auto;
    top: 0;
    transform: translateY(0);
    margin-top: 20px;
  }
}