.header {
  font-size: 12px;
  color: #ffffff;
  font-weight: 300;
  margin: 0;
  border: 1px solid #565656;
  padding: 5px 15px;
  border-radius: 30px;
  margin-bottom: 52px;
  display: inline-flex;
  align-items: center;

}
