.projects {
  padding-top: 90px;
  padding-bottom: 90px;
  overflow: hidden;
}

.container {
  max-width: 1130px;
  padding: 0 15px;
  margin: auto;
}

.content {
  max-width: 770px;
  width: 100%;
  margin-left: auto;
}


.projectCardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  max-width: 770px;
  width: 100%;
}

.projectCard {
  position: relative;
  transition: 0.5s ease;
}

.projectImg {
  width: 407px;
  height: 250px;
  margin: 0;
  border-radius: 10px;
}

.overlay {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: none;
  transition: 0.3s ease;
  background-color: #0f1011f3;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.overlay:hover {
  opacity: 1;
}

.overlay > div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

h3 {
  margin-top: 0;
}


.links {
  display: flex;
  flex-direction: row;
  gap: 70px;
  text-align: center;
  margin-bottom: 10px;
}

.description {
  text-align: center;
  margin-bottom: 15px;
}

.iconContainter {
  display: flex;
  gap: 15px;
}


@media screen and (max-width: 1246px) {
  .container {
    max-width: 767px;
  }
  
  .content {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
  }
}


/* @media screen and (max-width: 1360px) {
  .projectCardContainer {
    grid-template-columns: repeat(1, 1fr);
  }
} */


@media screen and (max-width: 900px) {
  .projectCardContainer {
    grid-template-columns: repeat(1, 1fr);
    justify-items:flex-start;
    align-items: center;
    justify-content: center;
  }
}


@media screen and (max-width: 500px) {
  .projectImg {
    width: 356px;
    height: 184px;
    margin: 0;
    border-radius: 10px;
  }

  .iconContainter {
    gap: 8px;
  }

  h3 {
    margin-bottom: 5px;
  }
}